import React, { useContext, useEffect } from "react";
import { uniqBy, flatten, uniq } from "lodash";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Select,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { selectPemifData } from "../../../store/reducers/pemifReducer";
import { useLocation } from "react-router-dom";
import { selectActivity } from "../../../store/reducers/activityReducer";
import {
  handleSelectedActivities,
  selectCandidates,
} from "../../../store/reducers/candidateReducer";
import { QualMapping } from "../../../utils/constants";
import {
  fetchActivitiesFromVaf,
  selectActivityFromVaf,
} from "../../../store/reducers/activityFromVafReducer";
import { useAuth } from "react-oidc-context";
import { UserContext } from "../../../context/UserContext";

const StaticData = ({ qual }) => {
  const location = useLocation();
  const { session_name, session_year, session_id } =
    useSelector(selectPemifData);
  const dispatch = useDispatch();
  const { activities } = useSelector(selectActivity);
  const { activitiesFromVaf, status } = useSelector(selectActivityFromVaf);
  const auth = useAuth();
  const accessToken = auth.user?.access_token;
  const { centre_number, centre_name } = useContext(UserContext);
  const { candidates = [], selectedActivities = {} } =
    useSelector(selectCandidates);

  useEffect(() => {
    // if (status === "idle")
      dispatch(
        fetchActivitiesFromVaf({
          qual: location.state?.name,
          token: accessToken,
          session_id,
          centre_number,
          centre_name,
        })
      );
  }, []);

  const getActivityType = (activityKey) => {
    return activityKey === "gcse_free_choice_activities"
      ? "Free"
      : activityKey === "gcse_indiv_lvl_activities"
      ? "Individual"
      : activityKey === "gcse_team_lvl_activities"
      ? "Team"
      : "";
  };
  let qualification =
    location.state?.name === QualMapping.ENTRY
      ? `ENTRY`
      : location.state?.name === QualMapping.GCSE
      ? `GCSE`
      : location.state?.name === QualMapping.ALEVEL
      ? `A`
      : `AS`;
  useEffect(() => {
    if (activitiesFromVaf && qualification) {
      let AllLevelActivities = {
        A: ["a_as_lvl_activities"],
        AS: ["a_as_lvl_activities"],
        GCSE: [
          "gcse_free_choice_activities",
          "gcse_indiv_lvl_activities",
          "gcse_team_lvl_activities",
        ],
        ENTRY: [
          "entry_lvl_team_activities",
          "entry_lvl_indiv_activities",
          "entry_lvl_free_choice_one_activities",
          "entry_lvl_free_choice_two_activities",
          "entry_lvl_perf_analysis",
        ],
      };
      let AllVafLevelActivities = {
        A: ["a_level_activities"],
        AS: ["as_level_activities"],
        GCSE: ["gcse_activities_individual", "gcse_activities_team"],
        ENTRY: ["entrylvl_activities_individual", "entrylvl_activities_team"],
      };
      let qualificationActivities = AllLevelActivities[qualification] || [];

      let savedActivitiesList = uniqBy(
        candidates.flatMap((candidate) =>
          qualificationActivities.flatMap((activityKey) => {
            return {
              ...(candidate[activityKey] || []),
              activity_type: getActivityType(activityKey),
            };
          })
        ),
        "activity_id"
      ).filter((act) => act?.activity_id);
      let qualificationVafActivities =
        AllVafLevelActivities[qualification] || [];
      qualificationVafActivities.forEach((key) => {});
      let flattenedActivities = flatten(
        qualificationVafActivities.map((val) => {
          return activitiesFromVaf[val] ? activitiesFromVaf[val] : {};
        })
      );
      let uniqueActivities = uniqBy(flattenedActivities, "activity_id");
      let vafSavedActivitiesList = uniqueActivities
        .filter(
          (act) =>
            (act.males && parseInt(act.males) !== 0) ||
            (act.females && parseInt(act.females) !== 0)
        )
        .map((act) => {
          return {
            ...act,
            activity_name: act.name,
          };
        });
      dispatch(
        handleSelectedActivities({
          savedActivitiesList: [
            ...savedActivitiesList,
            ...vafSavedActivitiesList,
          ],
          qualification, // Combine the two arrays here
        })
      );
    }
  }, [qualification,dispatch,location.state?.name,centre_name,centre_number,activitiesFromVaf,location.pathname]);

  const options = uniqBy(
    [
      ...(activities?.male_Activities || []),
      ...(activities?.female_Activities || []),
    ],
    "activity_id"
  );

  // Sort by name, fix for female activities appearing at end of list
  options.sort((a, b) => (a.activity_name > b.activity_name ? 1 : -1));

  // CASS-1046 : Removing activities from dropdown hides them on table
  // TODO: Add handling for other qualification types
  const isActivityInCandidateList = (activityId) =>
    candidates.filter(
      (x) =>
        (location.state?.name === QualMapping.ALEVEL &&
          x.a_as_lvl_activities.activity_id == activityId) ||
        (location.state?.name === QualMapping.ASLEVEL &&
          x.a_as_lvl_activities.activity_id == activityId) ||
        (location.state?.name === QualMapping.GCSE &&
          x.gcse_team_lvl_activities.activity_id == activityId) ||
        (location.state?.name === QualMapping.GCSE &&
          x.gcse_indiv_lvl_activities.activity_id == activityId) ||
        (location.state?.name === QualMapping.GCSE &&
          x.gcse_free_choice_activities.activity_id == activityId) ||
        (location.state?.name === QualMapping.ENTRY &&
          x.entry_lvl_indiv_activities.activity_id == activityId) ||
        (location.state?.name === QualMapping.ENTRY &&
          x.entry_lvl_free_choice_one_activities.activity_id == activityId) ||
        (location.state?.name === QualMapping.ENTRY &&
          x.entry_lvl_free_choice_two_activities.activity_id == activityId) ||
        (location.state?.name === QualMapping.ENTRY &&
          x.entry_lvl_team_activities.activity_id == activityId)
    ).length > 0;

  const handleChange = (event) => {
    let savedActivitiesList = options.filter(
      (val) =>
        event.target.value.includes(val.activity_name) ||
        // CASS-1046 : Removing activities from dropdown hides them on table
        // Ensure any activities selected in the main table are also in the saved list
        isActivityInCandidateList(val.activity_id)
    );

    dispatch(handleSelectedActivities({ savedActivitiesList, qualification }));
  };
  return (
    <>
      <>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              mb: 1,
              fontSize: {
                xs: "15px !important",
                sm: "20px !important",
                md: "20px !important",
              },
            }}
          >
            {location.state?.name === QualMapping.ENTRY
              ? `Entry Level Physical Education`
              : location.state?.name === QualMapping.GCSE
              ? `GCSE Physical Education`
              : location.state?.name === QualMapping.ALEVEL
              ? `A Level Physical Education`
              : `AS Level Physical Education`}{" "}
            activity marks submission {session_name} {session_year}
          </Typography>
          <Typography variant="body2">For each candidate:</Typography>
          <List
            sx={{
              lineHeight: 0,
              listStyleType: "disc",
              marginLeft: "0.75em",
              "& .MuiListItem-root": {
                display: "list-item",
                px: 0.5,
                py: 0.5,
              },
            }}
          >
            <ListItem>
              <ListItemText>
                <Typography variant="body2">
                  Select the activity and role from the drop-down lists. You can
                  use the activity filter to select the activities your cohort
                  has completed.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body2">
                  Enter each event, skill or position and the marks for these.
                  The overall activity mark will be calculated automatically.
                </Typography>
              </ListItemText>
            </ListItem>
            {!(qual === QualMapping.ENTRY || qual === QualMapping.GCSE) && (
              <ListItem>
                <ListItemText>
                  <Typography variant="body2">
                    If they were part of your on-site sample, select 'Yes' in
                    the final column.
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            <Typography variant="body2" sx={{ lineHeight: "1.8", mt: 1 }}>
              Click 'Submit' to submit your marks. To save your data without
              submitting, click 'Save and close' and your details will be saved
              for later.
            </Typography>
          </List>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1, ml: 2 }}
          >
            <Typography variant="body2" sx={{ lineHeight: "1.8" }}>
              {" "}
              Activities{" "}
            </Typography>
            <FormControl>
              <InputLabel sx={{ textAlign: "center", top: "-7px" }}>
                Select Options
              </InputLabel>
              <Select
                label="Select Options"
                multiple
                value={
                  (selectedActivities &&
                    selectedActivities[qualification] &&
                    selectedActivities[qualification].length &&
                    selectedActivities[qualification].map(
                      (value) => value.activity_name
                    )) ||
                  []
                }
                onChange={handleChange}
                renderValue={(selected) =>
                  selected.length > 1
                    ? "<Multiple Activities Selected>"
                    : selected.length > 0
                    ? selected[0]
                    : ""
                }
                sx={{
                  width: 300,
                  height: 36,
                  fontSize: 14,
                  padding: "4px 8px",
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.activity_id}
                    value={option.activity_name}
                    // CASS-1046 : Removing activities from dropdown hides them on table
                    // Disable any items in the candidate table to prevent them being removed
                    disabled={isActivityInCandidateList(option.activity_id)}
                  >
                    <Checkbox
                      checked={
                        (
                          selectedActivities[qualification].map(
                            (value) => value.activity_name
                          ) || []
                        ).indexOf(option.activity_name) > -1 ||
                        // CASS-1046 : Removing activities from dropdown hides them on table
                        // Check any items that are selected in the candidate table
                        isActivityInCandidateList(option.activity_id)
                      }
                    />
                    <ListItemText primary={option.activity_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </>
    </>
  );
};
export default StaticData;
