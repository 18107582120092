import React from "react";
import { useState, useMemo, useEffect } from "react";
import "./marksubmission.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Button,
  Typography,
  Select,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  selectCandidates,
  handleChangeGcse,
  calculateMarks,
  calculateOverallMarkForGCSEAndEntry,
} from "../../../store/reducers/candidateReducer";
import { selectFirstEvent } from "../../../store/reducers/eventReducer";
import {
  resetRestrictedActivity,
  selectRestrictedActivity,
} from "../../../store/reducers/restrictedActivityReducer";
import { checkStatus } from "./commonFuntions";
import { useSelector, useDispatch } from "react-redux";
import { selectActivity } from "../../../store/reducers/activityReducer";
import {
  renderActivitiesRows,
  renderEventRows,
  renderMarkRows,
  renderSexRows,
  renderEvidenceRows,
  renderActivityTypeRows,
} from "./GcseLevelCell";
import { useAuth } from "react-oidc-context";
import { inputStyleSecondary, tableInputStyle } from "../../../utils/Theme";
import { selectExceptionActivity } from "../../../store/reducers/exceptionalActivityReducer";
import { useLocation } from "react-router-dom";
import { QualMapping } from "../../../utils/constants";
import { sortEvents } from "../../../utils/commonFunctions";

const GCSELevelTable = () => {
  const auth = useAuth();
  const acessToken = auth.user?.access_token;
  const dispatch = useDispatch();
  const { activities } = useSelector(selectActivity);
  const { candidates, selectedActivities = {} } = useSelector(selectCandidates);
  const location = useLocation();
  let qualification =
    location.state?.name === QualMapping.ENTRY
      ? `Entry`
      : location.state?.name === QualMapping.GCSE
        ? `GCSE`
        : location.state?.name === QualMapping.ALEVEL
          ? `A`
          : `AS`;
  const { noEvents } = useSelector(selectExceptionActivity);

  const {
    first_events,
    first_event_row_id,
    second_event_row_id,
    third_event_row_id,
    second_events,
    third_events,
    first_event_row_type,
    second_event_row_type,
    third_event_row_type,
  } = useSelector(selectFirstEvent);
  const restrictedActivity = useSelector(selectRestrictedActivity);
  let rowData = candidates ? [...candidates] : [];

  const rows = rowData.map((row, i) => {
    const baseSubRow = [
      {
        activity_type_name: "Individual",
        activityType: "gcse_indiv_lvl_activities",
        sex: row.sex,
        candidate_number: row.candidate_number,
        candidate_name: row.candidate_name,
        ...row.gcse_indiv_lvl_activities,
        restrictedActivities:
          restrictedActivity && restrictedActivity[i]
            ? restrictedActivity[i]
            : null,
      },
      {
        activity_type_name: "Free",
        activityType: "gcse_free_choice_activities",
        sex: row.sex,
        candidate_number: row.candidate_number,
        candidate_name: row.candidate_name,
        ...row.gcse_free_choice_activities,
        restrictedActivities:
          restrictedActivity && restrictedActivity[i]
            ? restrictedActivity[i]
            : null,
      },
    ];

    return {
      ...row,
      ...row.gcse_team_lvl_activities,
      activity_type_name: "Team",
      activity_types: "Team, Individual, Free Choice",
      activityRows: [...baseSubRow],
    };
  });

  const [data, setData] = useState(rows);


  const getFirstEventOptions = (params) => {
    return params.row.getValue("sex") === "F" &&
      params.row.getValue("activity_id") &&
      activities?.female_Activities &&
      activities?.female_Activities.filter(
        (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
      )[0].first_events &&
      activities?.female_Activities
        .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
        .first_events.filter(
          (x) =>
            x.event_sex_restriction == "None" ||
            x.event_sex_restriction == "Female"
        )
      ? activities?.female_Activities
        .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
        .first_events.filter(
          (x) =>
            x.event_sex_restriction == "None" ||
            x.event_sex_restriction == "Female"
        )
        .map((x) => ({ value: x.event_id, label: x.event_display_name }))
      : params.row.getValue("sex") === "M" &&
        params.row.getValue("activity_id") &&
        activities?.male_Activities &&
        activities?.male_Activities.filter(
          (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
        )[0].first_events &&
        activities?.male_Activities
          .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
          .first_events.filter(
            (x) =>
              x.event_sex_restriction == "None" ||
              x.event_sex_restriction == "Male"
          )
        ? activities?.male_Activities
          .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
          .first_events.filter(
            (x) =>
              x.event_sex_restriction == "None" ||
              x.event_sex_restriction == "Male"
          )
          .map((x) => ({ value: x.event_id, label: x.event_display_name }))
        : [];
  };

  const getSecondEventOptions = (params) => {
    return params.row.getValue("sex") === "F" &&
      params.row.getValue("activity_id") &&
      params.row.getValue("first_event_id") &&
      activities?.female_Activities &&
      activities?.female_Activities
        .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
        ?.first_events?.filter(
          (x) => x.event_id === params.row.getValue("first_event_id")
        )[0]?.next_level_events
      ? activities?.female_Activities
        .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
        ?.first_events?.filter(
          (x) => x.event_id === params.row.getValue("first_event_id")
        )[0]
        ?.next_level_events?.filter(
          (x) =>
            x.event_sex_restriction === "None" ||
            x.event_sex_restriction == "Female"
        )
        .map((x) => ({ value: x.event_id, label: x.event_display_name }))
      : params.row.getValue("sex") === "M" &&
        params.row.getValue("activity_id") &&
        params.row.getValue("first_event_id") &&
        activities?.male_Activities &&
        activities?.male_Activities
          .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
          ?.first_events?.filter(
            (x) => x.event_id === params.row.getValue("first_event_id")
          )[0]?.next_level_events
        ? activities?.male_Activities
          .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
          ?.first_events?.filter(
            (x) => x.event_id === params.row.getValue("first_event_id")
          )[0]
          ?.next_level_events?.filter(
            (x) =>
              x.event_sex_restriction === "None" ||
              x.event_sex_restriction == "Male"
          )
          .map((x) => ({ value: x.event_id, label: x.event_display_name }))
        : [];
  };

  const getThirdEventOptions = (params) => {
    return params.row.getValue("sex") === "F" &&
      params.row.getValue("activity_id") &&
      params.row.getValue("first_event_id") &&
      params.row.getValue("second_event_id") &&
      activities?.female_Activities &&
      activities?.female_Activities
        .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
        ?.first_events?.filter(
          (x) => x.event_id === params.row.getValue("first_event_id")
        )[0]?.next_level_events &&
      activities?.female_Activities
        .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
        ?.first_events?.filter(
          (x) => x.event_id === params.row.getValue("first_event_id")
        )[0]
        ?.next_level_events.filter(
          (x) => x.event_id === params.row.getValue("second_event_id")
        )[0]?.next_level_events
      ? activities?.female_Activities
        .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
        ?.first_events?.filter(
          (x) => x.event_id === params.row.getValue("first_event_id")
        )[0]
        ?.next_level_events.filter(
          (x) => x.event_id === params.row.getValue("second_event_id")
        )[0]
        ?.next_level_events?.filter(
          (x) =>
            x.event_sex_restriction === "None" ||
            x.event_sex_restriction == "Female"
        )
        .map((x) => ({ value: x.event_id, label: x.event_display_name }))
      : params.row.getValue("sex") === "M" &&
        params.row.getValue("activity_id") &&
        params.row.getValue("first_event_id") &&
        params.row.getValue("second_event_id") &&
        activities?.male_Activities &&
        activities?.male_Activities
          .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
          ?.first_events?.filter(
            (x) => x.event_id === params.row.getValue("first_event_id")
          )[0]?.next_level_events &&
        activities?.male_Activities
          .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
          ?.first_events?.filter(
            (x) => x.event_id === params.row.getValue("first_event_id")
          )[0]
          ?.next_level_events.filter(
            (x) => x.event_id === params.row.getValue("second_event_id")
          )[0]?.next_level_events
        ? activities?.male_Activities
          .filter((x) => x.activity_id === parseInt(params.row.getValue("activity_id")))[0]
          ?.first_events?.filter(
            (x) => x.event_id === params.row.getValue("first_event_id")
          )[0]
          ?.next_level_events.filter(
            (x) => x.event_id === params.row.getValue("second_event_id")
          )[0]
          ?.next_level_events?.filter(
            (x) =>
              x.event_sex_restriction === "None" ||
              x.event_sex_restriction == "Male"
          )
          .map((x) => ({ value: x.event_id, label: x.event_display_name }))
        : [];
  };

  const getNumber = (x) => {
    return isNaN(parseInt(x)) ? 0 : parseInt(x);
  };

  // Exclude user from selecting same activity or restricted activities (i.e. activities that cannot be combined)
  const includeActivity = (activity, row) => {
    let include = true;

    if (row.depth === 0) {
      // Top level row, check all subrows for potential duplicates
      row.subRows.forEach((x) => {
        if (parseInt(x.getValue("activity_id")) === activity.activity_id) include = false;
        else if (activity.restricted_activities) {
          // Check for any restrictions
          activity.restricted_activities.forEach((y) => {
            if (y === parseInt(x.getValue("activity_id"))) include = false;
          });
        }
      });
    } else {
      // Get parent, check parent and childrows for any duplicates
      const parent = row.getParentRow();
      if (parseInt(parent.getValue("activity_id")) === activity.activity_id)
        include = false;
      else if (activity.restricted_activities) {
        // Check for any restrictions
        activity.restricted_activities.forEach((y) => {
          if (y === parseInt(parent.getValue("activity_id"))) include = false;
        });
      }

      parent.subRows.forEach((x) => {
        if (
          x.index !== row.index &&
          parseInt(x.getValue("activity_id")) === activity.activity_id
        )
          include = false;
        else if (activity.restricted_activities) {
          // Check for any restrictions
          activity.restricted_activities.forEach((y) => {
            if (y === parseInt(x.getValue("activity_id"))) include = true;
          });
        }
      });
    }
    return include;
  };

  const resetMarks = (row, markNumber, includeSubRows) => {
    if (markNumber == 1) {
      row._valuesCache.first_event_mark = "";
      if (includeSubRows)
        row.subRows.forEach((x) => {
          x._valuesCache.first_event_mark = "";
        });
    }

    if (markNumber <= 2) {
      row._valuesCache.second_event_mark = "";
      if (includeSubRows)
        row.subRows.forEach((x) => {
          x._valuesCache.second_event_mark = "";
        });
    }

    if (markNumber <= 3) {
      row._valuesCache.third_event_mark = "";
      if (includeSubRows)
        row.subRows.forEach((x) => {
          x._valuesCache.third_event_mark = "";
        });
    }

    // Update the activity mark
    row._valuesCache.total_for_activity = calculateMarks(row._valuesCache, {
      payload: { qual: "gcse" },
    });
    if (includeSubRows)
      row.subRows.forEach((x) => {
        x._valuesCache.total_for_activity = calculateMarks(x._valuesCache, {
          payload: { qual: "gcse" },
        });
      });
    // Update overall mark
    if (row.depth == 0)
      row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([
        row._valuesCache,
        ...row.subRows.map((x) => x._valuesCache),
      ]);
    else
      row.getParentRow()._valuesCache.overall_mark =
        calculateOverallMarkForGCSEAndEntry([
          row.getParentRow()._valuesCache,
          ...row.getParentRow().subRows.map((x) => x._valuesCache),
        ]);
  };

  // const checkStatus = ({ row }) => {
  //   if (
  //     row.gcse_team_lvl_activities.candidate_attendance_status === "A" &&
  //     row.gcse_indiv_lvl_activities.candidate_attendance_status === "A" &&
  //     row.gcse_free_choice_activities.candidate_attendance_status === "A"
  //   )
  //     return "Not available";
  //   else if (
  //     row.gcse_team_lvl_activities.candidate_attendance_status === "W" &&
  //     row.gcse_indiv_lvl_activities.candidate_attendance_status === "W" &&
  //     row.gcse_free_choice_activities.candidate_attendance_status === "W"
  //   )
  //     return "Withdrawn";
  //   else return "Present";
  // };
  const getRowHeight = (params) => 120;

  const isDisabled = (row) =>
    row.getValue("sex") === "Unknown" ||
    row.original.candidate_attendance_status === "A" ||
    row.original.candidate_attendance_status === "W";


  const isFirstEventMarkDisabled = (row) =>
    !row.getValue("sex") ||
    !row.getValue("activity_id");

  const isSecondEventMarkDisabled = (row) =>
    !row.getValue("sex") ||
    !row.getValue("activity_id") ||
    !row.getValue("first_event_id") ||
    !row.getValue("second_event_id") ||
    (row.getValue("sex") === "F" &&
      activities?.female_Activities &&
      !activities?.female_Activities.filter(
        (x) => x.activity_id === parseInt(row.getValue("activity_id"))
      )[0]?.first_events) ||
    (row.getValue("sex") === "M" &&
      activities?.male_Activities &&
      !activities?.male_Activities.filter(
        (x) => x.activity_id === parseInt(row.getValue("activity_id"))
      )[0]?.first_events) ||
    (row.getValue("sex") === "M" &&
      activities?.male_Activities &&
      (!activities?.male_Activities.filter(
        (x) => x.activity_id === parseInt(row.getValue("activity_id"))
      )[0]?.first_events ||
        !activities?.male_Activities
          .filter(
            (x) => x.activity_id === parseInt(row.getValue("activity_id"))
          )[0]
          ?.first_events?.filter(
            (x) => x.event_id === row.getValue("first_event_id")
          )[0]?.next_level_events)) ||
    (row.getValue("sex") === "F" &&
      activities?.female_Activities &&
      (!activities?.female_Activities.filter(
        (x) => x.activity_id === parseInt(row.getValue("activity_id"))
      )[0]?.first_events ||
        !activities?.female_Activities
          .filter(
            (x) => x.activity_id === parseInt(row.getValue("activity_id"))
          )[0]
          ?.first_events?.filter(
            (x) => x.event_id === row.getValue("first_event_id")
          )[0]?.next_level_events));

  const isThirdEventMarkDisabled = (row) =>
    !row.getValue("sex") ||
    !row.getValue("activity_id") ||
    !row.getValue("first_event_id") ||
    !row.getValue("second_event_id") ||
    !row.getValue("third_event_id") ||
    (row.getValue("sex") === "F" && !activities?.female_Activities) ||
    (row.getValue("sex") === "M" && !activities?.male_Activities) ||
    (row.getValue("sex") === "F" &&
      !activities?.female_Activities.filter(
        (x) => x.activity_id === parseInt(row.getValue("activity_id"))
      )[0]?.first_events) ||
    (row.getValue("sex") === "M" &&
      !activities?.male_Activities.filter(
        (x) => x.activity_id === parseInt(row.getValue("activity_id"))
      )[0]?.first_events) ||
    (row.getValue("sex") === "F" &&
      !activities?.female_Activities
        .filter(
          (x) => x.activity_id === parseInt(row.getValue("activity_id"))
        )[0]
        ?.first_events?.filter(
          (x) => x.event_id === row.getValue("first_event_id")
        )[0]?.next_level_events) ||
    (row.getValue("sex") === "M" &&
      !activities?.male_Activities
        .filter(
          (x) => x.activity_id === parseInt(row.getValue("activity_id"))
        )[0]
        ?.first_events?.filter(
          (x) => x.event_id === row.getValue("first_event_id")
        )[0]?.next_level_events) ||
    (row.getValue("sex") === "F" &&
      !activities?.female_Activities
        .filter(
          (x) => x.activity_id === parseInt(row.getValue("activity_id"))
        )[0]
        ?.first_events.filter(
          (x) => x.event_id === row.getValue("first_event_id")
        )[0]
        ?.next_level_events.filter(
          (x) => x.event_id === row.getValue("second_event_id")
        )[0]?.next_level_events) ||
    (row.getValue("sex") === "M" &&
      !activities?.male_Activities
        .filter(
          (x) => x.activity_id === parseInt(row.getValue("activity_id"))
        )[0]
        ?.first_events.filter(
          (x) => x.event_id === row.getValue("first_event_id")
        )[0]
        ?.next_level_events.filter(
          (x) => x.event_id === row.getValue("second_event_id")
        )[0]?.next_level_events);


  const onChangeEvent = (event, row, activities, id) => {
    if (row.depth === 0)
      data[row.index][event.name] = event.value;
    else
      data[row.parentId].activityRows[row.index][event.name] = event.value;

    dispatch(
      handleChangeGcse({
        event: { name: event.name, value: event.value },
        data:
          row.getValue("sex") === "M"
            ? activities.male_Activities
            : activities.female_Activities,
        id: row.depth === 0 ? row.index : row.parentId,
        activityTypeCode:
          row.depth === 0
            ? "gcse_team_lvl_activities"
            : data[row.parentId].activityRows[row.index].activityType,
        qual: "gcse",
      })
    );
  };
  const prepopulateEvents = (row, eventType) => {
    const acts =
      row.getValue("sex") == "M"
        ? activities.male_Activities
        : activities.female_Activities;
    const gender = (row.getValue("sex") == 'M') ? 'Male' : 'Female';

    if (row.getValue("sex") && row.getValue("activity_id")) {
      let firstLevelEvents =
        (acts.filter((x) => x.activity_id === parseInt(row.getValue("activity_id")))[0]
          ?.first_events || []).filter(event => event.event_sex_restriction === gender || event.event_sex_restriction === 'None');
      // Check and populate first level events
      if (firstLevelEvents?.length == 1) {
        if (
          !row.getValue("first_event_id") ||
          row.getValue("first_event_id") != firstLevelEvents[0].event_id
        ) {
          // Populate second level event with new value
          row._valuesCache.first_event_id = firstLevelEvents[0].event_id;

          onChangeEvent(
            {
              name: "first_event_id",
              value: row._valuesCache.first_event_id,
            },
            row,
            activities,
            row.id
          );
        }
      }

      let secondLevelEvents =
        (firstLevelEvents.length &&
          firstLevelEvents.filter(
            (x) => x.event_id == row.getValue("first_event_id")
          )[0]?.next_level_events) ||
        [];

      // Check and populate second level events
      if (row.getValue("first_event_id") && secondLevelEvents?.length == 1) {
        if (row.getValue("second_event_id") != secondLevelEvents[0].event_id) {
          // Populate second level event with new value
          row._valuesCache.second_event_id = secondLevelEvents[0].event_id;

          onChangeEvent(
            {
              name: "second_event_id",
              value: row._valuesCache.second_event_id,
            },
            row,
            activities,
            row.id
          );
        }
      }

      let thirdLevelEvents =
        (secondLevelEvents.length &&
          secondLevelEvents.filter(
            (x) => x.event_id == row.getValue("second_event_id")
          )[0]?.next_level_events) ||
        [];

      // Check and populate third level events
      if (row.getValue("second_event_id") && thirdLevelEvents?.length == 1) {
        if (row.getValue("third_event_id") != thirdLevelEvents[0].event_id) {
          // Populate second level event with new value
          row._valuesCache.third_event_id = thirdLevelEvents[0].event_id;

          onChangeEvent(
            {
              name: "third_event_id",
              value: row._valuesCache.third_event_id,
            },
            row,
            activities,
            row.id
          );
        }
      }
    }
  };
  const restoreMissingEventIds = (row) => {
    let activityType = ''
    if (row.getValue("activity_type_name") === 'Team')
      activityType = 'gcse_team_lvl_activities'
    else if (row.getValue("activity_type_name") === 'Individual')
      activityType = 'gcse_indiv_lvl_activities'
    else if (row.getValue("activity_type_name") === 'Free')
      activityType = 'gcse_free_choice_activities'

    // Required to fix a weird issue where the event field is being cleared when it's been prepopulated and your tabbing between the gcseOptions
    const candidate = candidates.filter(x => x.candidate_number == row.getValue("candidate_number"))[0];
    if (candidate[activityType].first_event_id)
      row._valuesCache.first_event_id = candidate[activityType].first_event_id;
    // Re-populate the event Id values if not matching the data
    if (candidate[activityType].second_event_id)
      row._valuesCache.second_event_id = candidate[activityType].second_event_id;
    if (candidate[activityType].third_event_id)
      row._valuesCache.third_event_id = candidate[activityType].third_event_id;
  }
  const columns = useMemo(() => [
    {
      accessorKey: "candidate_number",
      enableEditing: false,
      sortingFn: "alphanumeric",
      header: "Candidate number",
      size: 120,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className:
          row.depth != 0 ? "candidate_number_childrow" : "candidate_number",
      }),
      Cell: (params) => {
        // Render the candidate number but make it invisible for childrows, this is to ensure the search still returns the info
        if (params.row.depth != 0)
          return (
            <Typography sx={{ display: "none" }}>
              {params.cell.getValue()}
            </Typography>
          );
        else return <Typography>{params.cell.getValue()}</Typography>;
      },
    },
    {
      accessorKey: "candidate_name",
      enableEditing: false,
      header: "Candidate name",
      size: 200,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className:
          row.depth != 0 ? "candidate_name_childrow" : "candidate_name",
      }),
      Cell: (params) => {
        // Render the candidate number but make it invisible for childrows, this is to ensure the search still returns the info
        if (params.row.depth != 0)
          return (
            <Typography sx={{ display: "none" }}>
              {params.cell.getValue()}
            </Typography>
          );
        else return <Typography>{params.cell.getValue()}</Typography>;
      },
    },
    {
      accessorKey: "sex",
      header: "Sex assigned at birth",
      size: 110,
      enableSorting: false,
      enableEditing: false,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className: row.depth != 0 ? "sex_childrow" : "sex",
      }),
      Cell: (params) => {
        return (
          <Typography>
            {params.row.depth == 0
              ? params.cell.getValue() === "F"
                ? "Female"
                : params.cell.getValue() === "M"
                  ? "Male"
                  : ""
              : ""}
          </Typography>
        );
      },
    },
    {
      accessorKey: "activity_type_name",
      enableSorting: false,
      header: "Type of activity",
      enableEditing: false,
      size: 100,
    },
    {
      accessorKey: "activity_id",
      enableSorting: false,
      header: "Activity",
      size: 180,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          if (row.depth === 0)
            data[row.index].activity_id = event.target.value;
          else
            data[row.parentId].activityRows[row.index].activity_id = event.target.value;

          row._valuesCache.activity_id = event.target.value;
            row._valuesCache.first_event_id = '';
            row._valuesCache.second_event_id = '';
            row._valuesCache.third_event_id = '';
          // Set whether filmed evidence is required (required for all off-site activities)
          const actList =
            row.getValue("sex") === "M"
              ? activities.male_Activities
              : activities.female_Activities;
          row._valuesCache.filmed_evidence_to_be_provided =
            actList.filter((x) => x.activity_id === parseInt(event.target.value))[0]
              .activity_type == "on-site"
              ? "N"
              : "Y";

          resetMarks(row, 1, false);

          dispatch(
            handleChangeGcse({
              event: {
                name: column.id,
                value: event.target.value,
              },
              data:
                row.getValue("sex") === "M"
                  ? activities.male_Activities
                  : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode:
                row.depth === 0
                  ? "gcse_team_lvl_activities"
                  : data[row.parentId].activityRows[row.index].activityType,
              qual: "gcse",
            })
          );
          if (row.depth === 0)
            data[row.index].activity_id = event.target.value;
          else
            data[row.parentId].activityRows[row.index].activity_id = event.target.value;

          // Do the prepopulate after the change event, the change event is resetting fields and preventing the prepopulate
          prepopulateEvents(row, "role");
        };
        const selectedActIds = (selectedActivities && Object.keys(selectedActivities).length && selectedActivities[qualification].length) ? selectedActivities[qualification].map(x => parseInt(x.activity_id)) : [];

        const actList =
          (row.getValue("sex") === "F" && activities?.female_Activities
            ? activities?.female_Activities
              .filter(
                (x) =>
                  (x.activity_type === row.getValue("activity_type_name") ||
                    row.getValue("activity_type_name") === "Free") &&
                  includeActivity(x, row)
              )
              .map((x) => ({ value: x.activity_id, label: x.activity_name }))
            : row.getValue("sex") === "M" && activities?.male_Activities
              ? activities?.male_Activities
                .filter(
                  (x) =>
                    (x.activity_type === row.getValue("activity_type_name") ||
                      row.getValue("activity_type_name") === "Free") &&
                    includeActivity(x, row)
                )
                .map((x) => ({ value: x.activity_id, label: x.activity_name }))
              : []).filter(x => (selectedActIds.length == 0 ? true : selectedActIds.includes(x.value))) || [];

        return (
          <Select
            sx={tableInputStyle}
            value={cell.getValue()}
            onChange={onChange}
            disabled={isDisabled(row)}
          >
            {(actList || []).map((activity) => {
              return (
                <MenuItem key={activity?.value} value={activity?.value}>
                  {activity?.label}
                </MenuItem>
              );
            })}
          </Select>
        );
      },

      Cell: (params) => {
        return <Typography>{params.cell.getValue()}</Typography>;
      },
    },
    {
      accessorKey: "first_event_id",
      header: "First event/skill/ position",
      size: 165,
      enableSorting: false,
      editVariant: "select",
      editSelectOptions: (params) => {
        return sortEvents(getFirstEventOptions(params));
      },
      muiEditTextFieldProps: (params) => ({
        id: "first_" + params.row.id,
        select: true,
        value:
          getFirstEventOptions(params).length === 1
            ? getFirstEventOptions(params)[0].value
            : params.row.getValue("first_event_id"),
        disabled:
          !params.row.getValue("sex") ||
          !params.row.getValue("activity_id") ||
          (params.row.getValue("sex") === "F" &&
            activities?.female_Activities &&
            !activities?.female_Activities.filter(
              (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
            )[0]?.first_events) ||
          (params.row.getValue("sex") === "M" &&
            activities?.male_Activities &&
            !activities?.male_Activities.filter(
              (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
            )[0]?.first_events),

        onChange: (event) => {
          params.row._valuesCache.first_event_id = event.target.value;
          resetMarks(params.row, 1, false);
          onChangeEvent(event.target, params.row, activities, 1);
          prepopulateEvents(params.row);

        },
      }),
    },
    {
      accessorKey: "first_event_mark",
      header: "Mark",
      size: 85,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          restoreMissingEventIds(row)
          // Dirty hack to prevent scrolling the table changing the value of this field
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 20) event.target.value = 20;
          if (event.target.value < 0) event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleChangeGcse({
              event: { name: column.id, value: event.target.value },
              data:
                row.getValue("sex") === "M"
                  ? activities.male_Activities
                  : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode:
                row.depth === 0
                  ? "gcse_team_lvl_activities"
                  : data[row.parentId].activityRows[row.index].activityType,
              qual: "gcse",
            })
          );

          // Update the activity mark
          row._valuesCache.total_for_activity = calculateMarks(
            row._valuesCache,
            { payload: { qual: "gcse" } }
          );

          // Update overall mark
          if (row.depth == 0)
            row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry(
              [row._valuesCache, ...row.subRows.map((x) => x._valuesCache)]
            );
          else
            row.getParentRow()._valuesCache.overall_mark =
              calculateOverallMarkForGCSEAndEntry([
                row.getParentRow()._valuesCache,
                ...row.getParentRow().subRows.map((x) => x._valuesCache),
              ]);
        };

        return (
          <TextField
            onChange={onChange}
            value={cell.getValue()}
            type="number"
            sx={{
              ...{ ...tableInputStyle },
              width: "100%",
            }}
            disabled={isFirstEventMarkDisabled(row)}
            // CASS-997 - Hide values and placeholder text when disabled
            placeholder={isFirstEventMarkDisabled(row) ? "" : "Mark"}
          />
        );
      },
    },
    {
      accessorKey: "second_event_id",
      header: "Second event/skill/ position",
      size: 165,
      enableSorting: false,
      editVariant: "select",
      editSelectOptions: (params) => {
        return sortEvents(getSecondEventOptions(params));
      },
      muiEditTextFieldProps: (params) => ({
        //    select: true,
        id: "second_" + params.row.id,
        value:
          getSecondEventOptions(params).length === 1
            ? getSecondEventOptions(params)[0].value
            : params.row.getValue("second_event_id"),
        disabled:
          !params.row.getValue("sex") ||
          !params.row.getValue("activity_id") ||
          !params.row.getValue("first_event_id") ||
          (params.row.getValue("sex") === "F" &&
            activities?.female_Activities &&
            !activities?.female_Activities.filter(
              (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
            )[0]?.first_events) ||
          (params.row.getValue("sex") === "M" &&
            activities?.male_Activities &&
            !activities?.male_Activities.filter(
              (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
            )[0]?.first_events) ||
          (params.row.getValue("sex") === "M" &&
            activities?.male_Activities &&
            (!activities?.male_Activities.filter(
              (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
            )[0]?.first_events ||
              !activities?.male_Activities
                .filter(
                  (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
                )[0]
                ?.first_events?.filter(
                  (x) => x.event_id === params.row.getValue("first_event_id")
                )[0]?.next_level_events)) ||
          (params.row.getValue("sex") === "F" &&
            activities?.female_Activities &&
            (!activities?.female_Activities.filter(
              (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
            )[0]?.first_events ||
              !activities?.female_Activities
                .filter(
                  (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
                )[0]
                ?.first_events?.filter(
                  (x) => x.event_id === params.row.getValue("first_event_id")
                )[0]?.next_level_events)),
        onChange: (event) => {
          resetMarks(params.row, 2, false);
          onChangeEvent(event.target, params.row, activities, 2);
          prepopulateEvents(params.row);
        },
      }),
    },
    {
      accessorKey: "second_event_mark",
      header: "Mark",
      size: 85,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          restoreMissingEventIds(row)
          // Dirty hack to prevent scrolling the table changing the value of this field
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 20) event.target.value = 20;
          if (event.target.value < 0) event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleChangeGcse({
              event: { name: column.id, value: event.target.value },
              data:
                row.getValue("sex") === "M"
                  ? activities.male_Activities
                  : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode:
                row.depth === 0
                  ? "gcse_team_lvl_activities"
                  : data[row.parentId].activityRows[row.index].activityType,
              qual: "gcse",
            })
          );

          // Update the activity mark
          row._valuesCache.total_for_activity = calculateMarks(
            row._valuesCache,
            { payload: { qual: "gcse" } }
          );
          // Update overall mark
          if (row.depth == 0)
            row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry(
              [row._valuesCache, ...row.subRows.map((x) => x._valuesCache)]
            );
          else
            row.getParentRow()._valuesCache.overall_mark =
              calculateOverallMarkForGCSEAndEntry([
                row.getParentRow()._valuesCache,
                ...row.getParentRow().subRows.map((x) => x._valuesCache),
              ]);
        };

        return (
          <TextField
            onChange={onChange}
            value={cell.getValue()}
            type="number"
            sx={{
              ...{ ...tableInputStyle },
              width: "100%",
            }}
            disabled={isSecondEventMarkDisabled(row)}
            // CASS-997 - Hide values and placeholder text when disabled
            placeholder={isSecondEventMarkDisabled(row) ? "" : "Mark"}
          />
        );
      },
    },
    {
      accessorKey: "third_event_id",
      header: "Third event/skill/ position",
      size: 165,
      enableSorting: false,
      editVariant: "select",
      editSelectOptions: (params) => {
        return sortEvents(getThirdEventOptions(params));
      },
      muiEditTextFieldProps: (params) => ({
        //    select: true,
        id: "third_" + params.row.id,
        value:
          getThirdEventOptions(params).length === 1
            ? getThirdEventOptions(params)[0].value
            : params.row.getValue("third_event_id"),
        disabled:
          !params.row.getValue("sex") ||
          !params.row.getValue("activity_id") ||
          !params.row.getValue("first_event_id") ||
          !params.row.getValue("second_event_id") ||
          (params.row.getValue("sex") === "F" &&
            !activities?.female_Activities) ||
          (params.row.getValue("sex") === "M" &&
            !activities?.male_Activities) ||
          (params.row.getValue("sex") === "F" &&
            !activities?.female_Activities.filter(
              (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
            )[0]?.first_events) ||
          (params.row.getValue("sex") === "M" &&
            !activities?.male_Activities.filter(
              (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
            )[0]?.first_events) ||
          (params.row.getValue("sex") === "F" &&
            !activities?.female_Activities
              .filter(
                (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
              )[0]
              ?.first_events?.filter(
                (x) => x.event_id === params.row.getValue("first_event_id")
              )[0]?.next_level_events) ||
          (params.row.getValue("sex") === "M" &&
            !activities?.male_Activities
              .filter(
                (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
              )[0]
              ?.first_events?.filter(
                (x) => x.event_id === params.row.getValue("first_event_id")
              )[0]?.next_level_events) ||
          (params.row.getValue("sex") === "F" &&
            !activities?.female_Activities
              .filter(
                (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
              )[0]
              ?.first_events.filter(
                (x) => x.event_id === params.row.getValue("first_event_id")
              )[0]
              ?.next_level_events.filter(
                (x) => x.event_id === params.row.getValue("second_event_id")
              )[0]?.next_level_events) ||
          (params.row.getValue("sex") === "M" &&
            !activities?.male_Activities
              .filter(
                (x) => x.activity_id === parseInt(params.row.getValue("activity_id"))
              )[0]
              ?.first_events.filter(
                (x) => x.event_id === params.row.getValue("first_event_id")
              )[0]
              ?.next_level_events.filter(
                (x) => x.event_id === params.row.getValue("second_event_id")
              )[0]?.next_level_events),
        onChange: (event) => {
          resetMarks(params.row, 3, false);
          if (params.row.depth === 0)
            data[params.row.index].third_event_id = event.target.value;
          else
            data[params.row.parentId].activityRows[params.row.index].third_event_id = event.target.value;

          dispatch(
            handleChangeGcse({
              event: { name: event.target.name, value: event.target.value },
              data:
                params.row.getValue("sex") === "M"
                  ? activities.male_Activities
                  : activities.female_Activities,
              id:
                params.row.depth === 0 ? params.row.index : params.row.parentId,
              activityTypeCode:
                params.row.depth === 0
                  ? "gcse_team_lvl_activities"
                  : data[params.row.parentId].activityRows[params.row.index]
                    .activityType,
              qual: "gcse",
            })
          );
        },
      }),
    },
    {
      accessorKey: "third_event_mark",
      header: "Mark",
      size: 85,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          restoreMissingEventIds(row)
          // Dirty hack to prevent scrolling the table changing the value of this field
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 20) event.target.value = 20;
          if (event.target.value < 0) event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleChangeGcse({
              event: { name: column.id, value: event.target.value },
              data:
                row.getValue("sex") === "M"
                  ? activities.male_Activities
                  : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode:
                row.depth === 0
                  ? "gcse_team_lvl_activities"
                  : data[row.parentId].activityRows[row.index].activityType,
              qual: "gcse",
            })
          );

          // Update the activity mark
          row._valuesCache.total_for_activity = calculateMarks(
            row._valuesCache,
            { payload: { qual: "gcse" } }
          );
          // Update overall mark
          if (row.depth == 0)
            row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry(
              [row._valuesCache, ...row.subRows.map((x) => x._valuesCache)]
            );
          else
            row.getParentRow()._valuesCache.overall_mark =
              calculateOverallMarkForGCSEAndEntry([
                row.getParentRow()._valuesCache,
                ...row.getParentRow().subRows.map((x) => x._valuesCache),
              ]);
        };

        return (
          <TextField
            onChange={onChange}
            value={cell.getValue()}
            type="number"
            sx={{
              ...{ ...tableInputStyle },
              width: "100%",
            }}
            disabled={
              isThirdEventMarkDisabled(row)
            }
            // CASS-997 - Hide values and placeholder text when disabled
            placeholder={isThirdEventMarkDisabled(row) ? "" : "Mark"}
          />
        );
      },
    },
    {
      accessorKey: "total_for_activity",
      header: "Total activity mark",
      size: 85,
      enableSorting: false,
      enableEditing: false,
      Cell: (params) => {
        return <Typography>{params.cell.getValue()}</Typography>;
      },
    },
    {
      accessorKey: "overall_mark",
      header: "Overall activity mark",
      size: 85,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className: row.depth != 0 ? "overallmark_childrow" : "overallmark",
      }),
      enableSorting: false,
      enableEditing: false,
      sortable: false,
      Cell: (params) => {
        if (params.row.depth == 0)
          params.row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([params.row._valuesCache, ...params.row.subRows.map(x => x._valuesCache)]);
        else
          params.row.getParentRow()._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([params.row.getParentRow()._valuesCache, ...params.row.getParentRow().subRows.map(x => x._valuesCache)]);
        return (
          <Typography>
            {params.row.original.candidate_attendance_status === "W"
              ? "Withdrawn"
              : params.row.original.candidate_attenance_status === "A"
                ? "Not Available" :
                !params.row.parentId ? params.cell.getValue() : ""
            }
          </Typography>
        );
      },
    },
  ]);

  const table = useMaterialReactTable({
    columns,
    data,
    filterFns: {
      numberandnameonlyfn: (row, id, filterValue) =>
        id === "candidate_number" || id === "candidate_name"
          ? row.getValue(id).indexOf(filterValue.toUpperCase()) >= 0
          : false,
    },
    enableColumnPinning: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnResizing: true,
    // CASS-1045 : Sorting of columns includes third state which confuses users
    enableSortingRemoval: false,
    // CASS-1049 : Remove toggle density from all tableInputStyle
    enableDensityToggle: false,
    enableHiding: false,
    layoutMode: "grid-no-grow", //constant column widths
    initialState: {
      sorting: [{ id: "candidate_number", desc: false }],
      columnPinning: { left: ["candidate_number", "candidate_name", "sex", "activity_type_name"] },
      expanded: true,
      density: "compact",
      pagination: { pageSize: 9, pageIndex: 0 },
    },
    enableExpandAll: false, //hide expand all double arrow in column header
    enableExpanding: true,
    filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
    getSubRows: (row) => row.activityRows, //default
    enableEditing: true,
    enableKeyboardShortcuts: false, // With our custom UI elements, set this to false to prevent underlying table cells getting focus.
    editDisplayMode: "table",
    muiPaginationProps: {
      rowsPerPageOptions: [9, 18, 36, 72, 99],
    },
    muiTableContainerProps: {
      sx: {
        overflowX: "auto", // Enable horizontal scrolling
      },
    },
    muiTableProps: {
      className: "GCSETable",
      sx: {
        minWidth: "1200px", // Ensure enough width for pinned columns
      },
    },
    muiTableBodyRowProps: ({ row }) => {
      return (
        row.original.candidate_attendance_status === "W" ||
          row.original.candidate_attendance_status === "A"
          ? {
            //conditionally style expanded rows
            sx: {
              backgroundColor: "#ccc",
            },
          }
          : {},
        { hover: false }
      );
    },
    muiTableBodyCellProps: {
      sx: {
        paddingLeft: "5px",
        padding: 0,
        textWrap: "wrap",
      },
    },
    muiTableHeadProps: {
      sx: {
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: "#8884b4",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#8884b4 ",
        "&:before": {
          backgroundColor: "#8884b4 !important",
        },
      },
    },
  });
  const paginationState = table.getState().pagination;
  // Force an update when pagination changes (ensures correct rendering) -Fix for overallmarks not updating for next pages 
  const [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    setForceUpdate(prev => prev + 1);
  }, [paginationState]);  // Dependency on paginationState to update when it changes

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
  // return (
  //   // <div style={{ height: 700, width: "100%" }}>
  //   <DataGrid
  //     sx={{
  //       "& .MuiDataGrid-cellContent": {
  //         textWrap: "wrap",
  //       },
  //     }}
  //     rows={rows}
  //     columns={columns}
  //     initialState={{
  //       pagination: {
  //         paginationModel: { page: 0, pageSize: 10 },
  //       },
  //     }}
  //     autoHeight
  //     checkboxSelection={false}
  //     onSelectionModelChange={() => {}}
  //     getRowHeight={getRowHeight}
  //     disableColumnMenu
  //   />
  //   // </div>
  // );
};

export default GCSELevelTable;
